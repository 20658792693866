<template>
  <div>
    <layout-vertical>

      <template>
        <div>
          <app-profile-alert
            v-if="isVerifyEmailLater && status && !isEmailVerified"
            class="mb-2"
          >
            <template #title>
              {{ $t('home.textToHelpYouRun') }} <span
                class="link text-success"
                @click="handleRequestVerifyEmail"
              >
                {{ $t('common.linkClickHere') }}
              </span> {{ $t('home.textToVerifyYourEmail') }}
            </template>
          </app-profile-alert>
          <div v-if="showStepGuide">
            <banner-header
              v-if="hasStepGuide && isExclusivePartner && $route.name === 'add-fund'"
              :img="require('@/assets/images/banner/banner-popup-adreach.png')"
            >
              <template #content>
                <div
                  class="btn-join-partnero"
                >
                  <div class="content-background">
                    <h1 class="title-ecomdy-affiliate">
                      <span class="text-opacity">Easy and automatic</span> Top-up
                    </h1>
                    <h1 class="title-ecomdy-affiliate">
                      in Pakistan Rupees <span class="text-opacity">now with Payfast!</span>
                    </h1>
                  </div>
                  <div class="btn-controller">
                    <div class="btn-learn-more">
                      <a
                        href="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
                        target="_blank"
                      >
                        {{ $t('referral.btnLearnMore') }}
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </banner-header>
            <!-- <banner-header
              v-if="hasStepGuide && autoTopUpAdAccountMode && !isExclusivePartner && $route.name === 'add-fund'"
              :img="require('@/assets/images/banner/banner-auto-topup.jpeg')"
              :link="guideToAutoTopUpAdAccount"
            /> -->

            <b-row>
              <b-col
                md="5"
                lg="3"
                class="d-none d-md-block"
              >
                <setup-guide-control />
                <list-event-slide
                  v-if="false"
                  class="mt-2"
                />
              </b-col>
              <b-col
                cols="12"
                class="d-md-none"
              >
                <setup-guide-mobile />
              </b-col>
              <b-col
                md="7"
                :lg="isTabRefundHistory ? '9' : '6'"
                cols="12"
                class="step-view"
              >
                <user-guide
                  v-if="showUserGuide"
                  class="d-lg-none"
                />

                <router-view />

                <list-event-slide
                  v-if="false"
                  class="mt-2 d-md-none"
                />
              </b-col>

              <b-col
                v-if="!isTabRefundHistory"
                lg="3"
                class="d-none d-lg-block position-sticky user-guide-container"
              >
                <user-guide v-if="showUserGuide" />
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
      </template>
      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar
          v-if="isAuthenticated"
          :toggle-vertical-menu-active="toggleVerticalMenuActive"
        />
        <div v-else />
      </template>

    <!--    <app-customizer-->
    <!--      v-if="showCustomizer"-->
    <!--      slot="customizer"-->
    <!--    />-->
    </layout-vertical>
    <adreach-survey-modal
      ref="open-modal-adreach-survey"
      :user-info="userInfo"
    />
    <send-email-success-modal ref="verifyEmailRef" />

    <banner-kickstart-modal
      :show="showModalKickstart"
      :img="require('@/assets/images/pages/payment/banner-top-up-payfast.png')"
      link="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
      @close-modal="onCloseModalKickstart"
    />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import SetupGuideMobile from '@/components/setup-guide-component/SetupGuideMobile.vue'
import SetupGuideControl from '@/components/setup-guide-component/SetupGuideControl.vue'
import ListEventSlide from '@/views/home/components/ListEventSlide.vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import AppProfileAlert from '@core/components/app-alert-top-bar/AppProfileAlert.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import SendEmailSuccessModal from '@core/components/app-alert-top-bar/SendEmailSuccessModal.vue'
import AdreachSurveyModal from '@/views/home/components/AdreachSurveyModal.vue'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'
import BannerKickstartModal from '@/views/home/components/BannerKickstartModal.vue'
import UserGuide from '@/components/UserGuide.vue'
import BannerHeader from '@/views/home/components/BannerHeader.vue'
import Navbar from '../components/Navbar.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')
export default {
  components: {
    SendEmailSuccessModal,
    AppProfileAlert,
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    SetupGuideControl,
    SetupGuideMobile,
    ListEventSlide,
    AdreachSurveyModal,
    BannerKickstartModal,
    UserGuide,
    BannerHeader,

    BRow,
    BCol,
  },

  mixins: [stepGuideMixin, toastification, envMixin],

  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      showModalKickstart: false,
      isTabRefundHistory: false,
    }
  },

  computed: {
    ...mapGetters(['user', 'status', 'error', 'loading', 'message', 'isAuthenticated']),
    userInfo() {
      return _get(this.user, ['data'])
    },

    showStepGuide() {
      const listPageNoNeedStepGuide = [
        'profile',
        'billing',
        'pricing',
        'referral-partner-info',
        'faq',
        'referral-introduce',
        'referral',
        'reactive-stripe',
        'affiliate-program',
      ]

      const currentPage = this.$route?.name
      const partnerPage = 'partner-management'
      const partnerInfoPage = 'partner-info-url'
      const memberPartnerPage = 'member'
      const pixelPageNames = [
        'pixel-management',
        'pixel-create',
        'pixel-detail',
      ]
      if (pixelPageNames?.includes(currentPage)) {
        return false
      }

      return this.hasStepGuide
        && !listPageNoNeedStepGuide?.includes(currentPage)
        && !currentPage?.includes(partnerPage)
        && !currentPage?.includes(memberPartnerPage)
        && !currentPage?.includes(partnerInfoPage)
    },
    isEmailVerified() {
      return this.user.data?.verifyEmailAt
    },
  },

  watch: {
    generalConfigs: {
      handler() {
        if (this.userInfo && this.userInfo?.onboardingStep === 2 && localStorage.getItem('isDoneSurvey') !== 'true' && this.isExclusivePartner) {
          this.openAdreachSurveyModal()
        } else if (localStorage.getItem('show-banner-modal') !== 'hide' && this.userInfo?.onboardingStep === 4 && this.isExclusivePartner) {
          this.showModalKickstart = true
        }
      },
      deep: true,
      immediate: true,
    },

    $route: {
      handler(route) {
        if (route?.query?.tabActive === 'refund_history') {
          this.isTabRefundHistory = true
        } else {
          this.isTabRefundHistory = false
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['requestVerifyEmail']),
    async handleRequestVerifyEmail() {
      if (this.loading) return

      await this.requestVerifyEmail()

      if (this.status) {
        this.$refs.verifyEmailRef.showModal()
      } else {
        this.toastFailure(this.message)
      }
    },

    openAdreachSurveyModal() {
      this.$refs['open-modal-adreach-survey'].showModal()
    },

    onCloseModalKickstart() {
      this.showModalKickstart = false
      localStorage.setItem('show-banner-modal', 'hide')
    },
  },
}
</script>

<style lang="scss" scoped>
  .link{
    text-decoration: underline;
    cursor: pointer;
  }

  .user-guide-container {
    height: max-content;
    top: 24px;
  }
</style>
